<button *ngIf="btnMat === 'none'" mat-button
    [type]="type"  [color]="color" [disableRipple]="disableRipple" [class]="class" [disabled]="disabled"
><ng-container *ngTemplateOutlet="cnt"></ng-container></button>

<button *ngIf="btnMat === 'raised'" mat-raised-button
        [type]="type"  [color]="color" [disableRipple]="disableRipple" [class]="class" [disabled]="disabled"
><ng-container *ngTemplateOutlet="cnt"></ng-container></button>

<button *ngIf="btnMat === 'stroked'" mat-stroked-button
        [type]="type"  [color]="color" [disableRipple]="disableRipple" [class]="class" [disabled]="disabled"
><ng-container *ngTemplateOutlet="cnt"></ng-container></button>

<button *ngIf="btnMat === 'flat'" mat-flat-button
        [type]="type"  [color]="color" [disableRipple]="disableRipple" [class]="class" [disabled]="disabled"
><ng-container *ngTemplateOutlet="cnt"></ng-container></button>



<ng-template #cnt>
    <ng-content></ng-content>
</ng-template>
