import { NgModule } from '@angular/core';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';
import { ModernLayoutModule } from 'app/layout/layouts/horizontal/modern/modern.module';
import { CompactLayoutModule } from 'app/layout/layouts/vertical/compact/compact.module';
import { SettingsModule } from 'app/layout/common/settings/settings.module';
import { SharedModule } from 'app/shared/shared.module';
import { BreakpointObserverDirective } from 'app/shared/directives/breakpoint-observer.directive';

const layoutModules = [
  // Empty
  EmptyLayoutModule,

  // Horizontal navigation
  ModernLayoutModule,

  // Vertical navigation
  CompactLayoutModule,
];

@NgModule({
  declarations: [LayoutComponent, BreakpointObserverDirective],
  imports: [SharedModule, SettingsModule, ...layoutModules],
  exports: [LayoutComponent, ...layoutModules],
})
export class LayoutModule {}
