import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-my-card',
    templateUrl: './my-card.component.html',
    styleUrls: ['./my-card.component.scss'],
    host: {
        'class': 'w-full bg-white rounded  min-h-14  flex flex-col overflow-hidden flex-shrink-0 relative',
        '[class.mat-elevation-z4]': '!disabled',
        '[class.border]': 'disabled',

    },
})
export class MyCardComponent {
    @Input() disabled = false;
}
