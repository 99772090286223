import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, FormControlName, NG_VALUE_ACCESSOR} from "@angular/forms";

export interface NameVal {
    name: string;
    value: any;
}

@Component({
    selector: 'app-selective-btns',
    templateUrl: './selective-btns.component.html',
    styleUrls: ['./selective-btns.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SelectiveBtnsComponent
        }
    ]
})
export class SelectiveBtnsComponent implements ControlValueAccessor, OnChanges {

    constructor() {
    }

    _list: NameVal[] = null;
    @Input() list: NameVal[] | string[] = null;
    @Input() debugMode: boolean = false;
    @Input() height: string = (12 * 4) + 'px';
    @Input() width: string = (14 * 4) + 'px';
    @Input('max-width') maxWidth: string = (14 * 4) + 'px';

    @Output() readonly input: EventEmitter<any> = new EventEmitter<any>();
    @Output() readonly change: EventEmitter<any> = new EventEmitter<any>();
    @Input() value: any = ''
    @Input() readonly disabled: boolean = false


    onChange = (quantity) => {
    };
    onTouched = () => {
    };
    touched = false;
    _disabled = false;


    writeValue(obj: string): void {
        this.value = obj;
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        this._disabled = disabled;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }


    ngOnChanges(changes: SimpleChanges): void {
        if ('list' in changes) {
            if (typeof changes.list.currentValue[0] === 'string') {
                this._list = (changes.list.currentValue as string[]).map((v, i) => {
                    return {name: v, value: i + ''}
                })
            } else if (changes.list.currentValue[0].value) {
                this._list = changes.list.currentValue
            } else {
                this.value = null;
            }
        }
        if ('disabled' in changes) {
            this.setDisabledState(changes.disabled.currentValue);
        }
    }


    changeValue(val: { name: string; value: any } | string) {
        if (this._disabled)
            return

        this.markAsTouched();
        if (typeof val === 'string') {
            this.value = val
        } else if (val.value != undefined) {
            this.value = val.value
        } else {
            this.value = null;
        }
        this.onChange(this.value);
        this.input.emit(this.value);
        this.change.emit(this.value);
    }
}
