import { ErrorHandler, ɵRuntimeError } from "@angular/core";

export class ExpressionChangedErrorSuppressor implements ErrorHandler {
  handleError(error: any): void {
      if (error instanceof ɵRuntimeError) {
          return;
      } else {
        console.error(error);
      }
  }
}