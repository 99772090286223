<button mat-icon-button [matMenuTriggerFor]="languages" type="button">
  <ng-container
    *ngTemplateOutlet="flagImage; context: { $implicit: activatedLang() }"
  />
</button>

<!-- Language menu -->
<mat-menu [xPosition]="'before'" #languages="matMenu">
  @for (lang of availableLangs(); track $index) {
  <button mat-menu-item (click)="onSelectLang(lang)">
    <span class="flex justify-center items-center gap-2">
      <ng-container
        *ngTemplateOutlet="flagImage; context: { $implicit: lang }"
      />
      <span>{{ lang?.title }}</span>
    </span>
  </button>
  }
</mat-menu>
<!-- Flag image template -->
<ng-template #flagImage let-lang>
  <span class="relative w-6 shadow rounded-sm overflow-hidden">
    <span
      class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"
    ></span>
    <img
      class="w-full"
      [src]="'assets/images/flags/' + lang?.id + '.svg'"
      [alt]="'Flag image for ' + lang?.title"
    />
  </span>
</ng-template>
