<div class="max-w-full flex overflow-x-auto h-full">
  <button
    mat-ripple
    [class.active]="isAllSelected"
    class="staff-item-btn"
    (click)="onSelectMember()"
  >
    <div class="flex-grow flex justify-center items-center">
      <div class="icon-container">
        {{ "all" | transloco }}
      </div>
    </div>

    <h3 class="font-bold text-center px-2 leading-none">
      {{ "all" | transloco }}
    </h3>
  </button>

  <button
    *ngFor="let member of staff"
    mat-ripple
    [class.active]="isSelectedMember(member?.id)"
    class="staff-item-btn"
    (click)="onSelectMember(member)"
  >
    @if (member.name | staffName; as name) {
    <div class="flex-grow flex justify-center items-center">
      <div class="icon-container">
        {{
          ("_dir" | transloco) === "ltr"
            ? name?.en[0] ?? name?.ar[0]
            : !!name?.ar
            ? name?.ar[0]
            : name?.en[0]
        }}
      </div>
    </div>

    <h3 class="font-bold text-center px-2 leading-none">
      {{
        ("_dir" | transloco) === "ltr"
          ? name?.en ?? name?.ar
          : !!name?.ar
          ? name?.ar
          : name?.en
      }}
    </h3>
    }
  </button>
</div>
