import { defaultsDeep } from "lodash";
import {
  getValueAfterTax,
  getValueBeforeTax
} from "../tax-calculator/tax-calculator";
import { Numeric } from "../types/common";
import { float } from "../float/float";

export enum DiscountType {
  Amount = 0,
  Percentage
}

export interface Discount {
  type: DiscountType;
  value: number;
}

export interface ValueAddedTax {
  percentage: number;
  useTax: boolean;
}

export interface InvoiceConfig {
  discount: Discount;
  fees: number;
  vat: ValueAddedTax;
  upfront: number;
  products: number[];
}

export interface Invoice extends InvoiceConfig {
  subtotal: number;
  total: number;
}

export interface Offer {}

export interface Service {}

export const useInvoiceConfig = (config: Partial<InvoiceConfig>): InvoiceConfig => (
  defaultsDeep(config, {
    discount: {
      type: DiscountType.Amount,
      value: 0.00
    },
    fees: 0.00,
    vat: {
      percentage: 0.00,
      useTax: false
    },
    products: [],
    upfront: 0.00
  })
);

export const calculateDiscount = ({
  discount, amount
}: { discount: Discount, amount: number }) => {
  if (discount.type === DiscountType.Amount) {
    return float(discount.value);
  } else {
    return float(amount) - float(float(discount.value) * float(amount))
  }
};

/**
 * Calculate the full invoice's details given its config.
 *
 * @param {InvoiceConfig} config
 * @returns {Invoice}
 */
export const createInvoice = (config: InvoiceConfig): Invoice => {
  let subtotal: number = 0.00;
  let total: number = 0.00;
  let calculatedVat: number = 0.00;

  if (config.vat.useTax) {
    let discountValue = config.discount.value;

    subtotal = config.products.reduce((sum, cur) =>
      sum + getValueBeforeTax({ tax: config.vat.percentage, value: cur }), 0);

    if (config.discount.type === DiscountType.Percentage) {
      discountValue = float(subtotal * float(float(config.discount.value) / 100));
    }

    const totalBeforeTax = float(
      float(subtotal)
      + float(config.fees)
    );

    total = getValueAfterTax({
      tax: config.vat.percentage,
      value: float(totalBeforeTax - float(discountValue))
    });

    calculatedVat = float(total - (totalBeforeTax - float(discountValue)));
  } else {
    subtotal = config.products.reduce((sum, cur) => sum + float(cur), 0.00);

    total = float(
      float(subtotal)
      + float(config.fees)
    );
  }

  return {
    ...config,
    subtotal,
    total,
    totalWithUnderpayment: config.upfront ? total - config.upfront : total,
    calculatedVat: config.vat.useTax ? calculatedVat : total
  } as Invoice;
};
