import { Component } from '@angular/core';
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'invalid-screen-size',
  standalone: true,
  templateUrl: './invalid-screen-size.component.html',
  styleUrls: ['./invalid-screen-size.component.scss'],
  imports: [
    TranslocoModule
  ]
})
export class InvalidScreenSizeComponent {}
