import { Pipe, PipeTransform } from '@angular/core';
import { ProfileService } from 'app/services/profile.service';
import { float } from 'app/shared/utilities/float/float';

@Pipe({ name: 'totalAmountBeforeTax' })
export class TotalAmountBeforeTaxPipe implements PipeTransform {
  public constructor(private readonly _profileService: ProfileService) {}

  public transform(
    totalAfterTax: number,
    basedOnSalon: boolean = true,
    voucherHasVat?: boolean
  ): number {
    if (basedOnSalon) {
      return this._profileService.salonHasVat()
        ? float(totalAfterTax * (100 / 115) || 0)
        : totalAfterTax;
    } else {
      return voucherHasVat
        ? float(totalAfterTax * (100 / 115) || 0)
        : totalAfterTax;
    }
  }
}
