import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeEqual',
})
export class TimeEqualPipe implements PipeTransform {
  public transform(...days: any[]): boolean {
    return (
      [...new Set(days.map((day) => new Date(day)?.getTime()))].length === 1
    );
  }
}
