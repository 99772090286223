import { Pipe, PipeTransform } from "@angular/core";

/**
 * Safely filter integers with commas.
 *
 * @class SafeIntPipe
 * @exports
 */
@Pipe({
  name: "safeInt"
})
export class SafeIntPipe implements PipeTransform {
  /**
   * 
   * @param {unknown} value
   * @returns {string}
   */
  transform(value: unknown): string {
    return String(value).replaceAll(",", "");
  }
}