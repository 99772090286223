import { Component, Input } from '@angular/core';
import {
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '../../../../@fuse/components/navigation';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  LayoutHeader,
  MyLayoutService,
} from '../../../services/my-layout.service';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { FuseMediaWatcherService } from '../../../../@fuse/services/media-watcher';
import { TranslocoService } from '@ngneat/transloco';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() pageTitle: string = '';

  header: LayoutHeader = this._myLayout.header;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    public _myLayout: MyLayoutService,
    private readonly _translate: TranslocoService,
    private readonly _title: Title
  ) {}

  ngOnInit(): void {
    // Subscribe to navigation data

    //prepare header
    this._myLayout.header$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value) => {
        this.header = value;
      });

    this.prepareTitle();
    this._router.events
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.prepareTitle();
        }
      });
  }

  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  prepareTitle() {
    let title: string = '';
    let icon: string = '';
    if (this._activatedRoute.snapshot?.data.title)
      title = this._activatedRoute.snapshot?.data.title;
    if (this._activatedRoute.snapshot.firstChild?.data.title)
      title = this._activatedRoute.snapshot.firstChild?.data.title;
    if (this._activatedRoute.snapshot.firstChild?.firstChild?.data.title)
      title = this._activatedRoute.snapshot.firstChild?.firstChild.data.title;

    if (this._activatedRoute.snapshot?.data.icon)
      icon = this._activatedRoute.snapshot?.data.icon;
    if (this._activatedRoute.snapshot.firstChild?.data.icon)
      icon = this._activatedRoute.snapshot.firstChild?.data.icon;
    if (this._activatedRoute.snapshot.firstChild?.firstChild?.data.icon)
      icon = this._activatedRoute.snapshot.firstChild?.firstChild.data.icon;

    title !== 'new_res' &&
      this._title.setTitle(this._translate.translate(title));

    this._myLayout.setHeader({
      title: this._translate.translate(title),
      icon: icon,
      type: 'title',
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
