import { Directive, ElementRef, HostListener } from '@angular/core';
import { InvalidScreenService } from '../services';

@Directive({ selector: '[appBreakpointObserver]' })
export class BreakpointObserverDirective {
  public constructor(
    private readonly _elementRef: ElementRef,
    private readonly _invalidScreenService: InvalidScreenService
  ) {}

  @HostListener('window:resize')
  public onResize() {
    this.observeHeight();
  }

  public ngAfterViewInit() {
    this.observeHeight();
  }

  private observeHeight = (): void => {
    const element = this._elementRef.nativeElement;
    const height: number = element.clientHeight;
    const width: number = element.clientWidth;

    this._invalidScreenService.screenIsInvalid.next(
      height < 500 || width < 500
    );
  };
}
