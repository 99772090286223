import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Category, Offer, Service, Staff, StaffMember } from '@pink/types';
import { ApiResponse } from 'app/models/api/ApiResponse.Interface';
import { environment } from 'environments/environment';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  map,
  of,
  shareReplay,
  tap,
} from 'rxjs';
import { float } from 'app/shared/utilities/float/float';
import {
  Booking,
  Customer,
  Product,
} from '../utils/interfaces/edit-interfaces.interface';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class EditService {
  private readonly _http = inject(HttpClient);
  private readonly _router = inject(Router);

  public readonly booking = new BehaviorSubject<Booking>({} as Booking);

  public readonly selectedStaffMembers = new BehaviorSubject<StaffMember[]>([]);

  public readonly selectedCategory = new BehaviorSubject<Category | null>(null);

  public readonly navigationChange = new BehaviorSubject<string | null>(null);

  public readonly showOffers = new BehaviorSubject<boolean>(true);

  public readonly selectedStaffMembers$: Observable<StaffMember[]> =
    this.selectedStaffMembers.asObservable();

  public readonly selectedCategory$: Observable<Category | null> =
    this.selectedCategory.asObservable();

  public readonly showOffers$: Observable<boolean> =
    this.showOffers.asObservable();

  currentBooking$: Observable<Booking> = this.booking.asObservable();

  cache = {
    staff: null,
    categories: null,
    services: null,
    offers: null,
  };

  public patchBookingValues = (value: Booking): void => {
    this.booking.next({
      ...value,
      products: value.products?.map((prod: Product) => {
        return prod?.type == 'service'
          ? prod
          : {
              ...prod,
              amount: prod?.price,
              amountBefore: prod?.services?.reduce(
                (acc, val) => acc + val?.price * val?.quantity,
                0
              ),
            };
      }),
    });
  };

  public readonly filteredServices$: Observable<Service[]> = combineLatest([
    this.selectedStaffMembers$,
    this.selectedCategory$,
    this.findServices(),
  ]).pipe(
    map(([selectedMembers, selectedCategory, services]) => {
      if (selectedCategory?.id === -1 || !selectedCategory)
        this.showOffers.next(true);
      else this.showOffers.next(false);

      switch (true) {
        case selectedMembers.length === 0 && !!selectedCategory:
          return services.filter(
            (service: Service) => service?.category_id === selectedCategory?.id
          );

        case selectedMembers.length > 0 && !selectedCategory:
          return services.filter((service: Service) =>
            service.staff?.some((member: StaffMember) =>
              selectedMembers.some((sm: StaffMember) => member?.id === sm?.id)
            )
          );

        case selectedMembers.length > 0 && !!selectedCategory:
          return services
            .filter(
              (service: Service) =>
                service?.category_id === selectedCategory?.id
            )
            ?.filter((service: Service) =>
              service.staff?.some((member: StaffMember) =>
                selectedMembers.some((sm: StaffMember) => member?.id === sm?.id)
              )
            );

        default:
          return services;
      }
    })
  );

  private updateService = (service: any): void => {
    let products = [...this.booking.getValue().products];

    const index: number = products.findIndex(
      (product) => product.id == service.id && service.type == 'service'
    );

    products = products.map((prod: any, prodIndex: number) =>
      index === prodIndex ? { ...service } : prod
    );

    if (service.quantity < 1) products.splice(index, 1);

    this.booking.next({ ...this.booking.getValue(), products });
  };

  private updateOffer(service: any) {
    let products = [...this.booking.getValue().products];

    let offers = [...this.booking.getValue().offers];

    const index: number = products.findIndex(
      (product) => product.id == service.id && service.type == 'offer'
    );

    products = products.map((prod: any, prodIndex: number) =>
      index === prodIndex ? { ...service } : prod
    );

    if (service.quantity < 1) {
      products.splice(index, 1);
      offers = offers.filter((offer) => offer?.offer_id !== service?.id);
    }

    this.booking.next({ ...this.booking.getValue(), products, offers });
  }

  public addServiceOffer = (service: any, type: string): void => {
    if (type === 'service') this.addNewService(service);
    else this.addNewOffer(service);

    setTimeout(() => {
      document
        .querySelector('#lastProd')
        ?.scrollIntoView({ behavior: 'smooth' });
    });
  };

  private addNewService = (service: any): void => {
    const selectedMembers: StaffMember[] = this.selectedStaffMembers.getValue();

    let products: Product[] = [...this.booking.getValue().products];

    const index: number = products.findIndex(
      (prod) => prod?.id === service?.id
    );

    // Is Exist
    if (index >= 0) {
      products = products.map((prod: any, prodIndex: number) =>
        index === prodIndex ? { ...prod, quantity: prod?.quantity + 1 } : prod
      );
    } else {
      products = products.concat([
        {
          ...service,
          type: 'service',
          quantity: 1,
          staff: selectedMembers.map((member: StaffMember) => member?.id),
          price: float(Number(service.amount)),
        },
      ]);
    }

    this.booking.next({ ...this.booking.getValue(), products });
  };

  private addNewOffer = (service: any): void => {
    const selectedMembers: StaffMember[] = this.selectedStaffMembers.getValue();

    let products: Product[] = [...this.booking.getValue().products];

    products = products.concat([
      {
        ...service,
        type: 'offer',
        quantity: 1,
        price: float(Number(service.amount)),
        services: service.services.map((service) => ({
          ...service,
          staff: selectedMembers.map((member: StaffMember) => member?.id),
          price: float(Number(service.amount)),
        })),
      },
    ]);

    this.booking.next({ ...this.booking.getValue(), products });
  };

  setDate(date: string): void {
    this.booking.next({
      ...this.booking.getValue(),
      date,
    });
  }

  public setProduct = (product: Product): void => {
    if (product.type === 'offer') this.updateOffer(product);
    else this.updateService(product);
  };

  markAsGuest(): void {
    this.booking.next({
      ...this.booking.getValue(),
      customer_id: 0,
      customer_name: 'Guest',
      customer_mobile: '0',
    });
  }

  changeCustomer(customer: Customer) {
    this.booking.next({
      ...this.booking.getValue(),
      customer_id: customer.id,
      customer_name: customer.name,
      customer_mobile: customer.mobile,
    });
  }

  public findCustomerByMobileNumber = (
    mobile: string
  ): Observable<Customer> => {
    return this._http
      .get<ApiResponse<Customer>>(
        environment.baseUrl + '/find-customer/' + mobile
      )
      .pipe(map((response) => response.data));
  };

  findBookingById(id: string): Observable<Booking> {
    return this._http
      .get<ApiResponse<{ bookings: Booking }>>(
        environment.bookingUrl + '/bookings/' + id + '/edit'
      )
      .pipe(
        map(
          (response) =>
            ({
              ...response.data.bookings,
              products: [
                ...response.data.bookings.services.map((service) => ({
                  id: service.id,
                  name: service.name,
                  price: service.price,
                  price_item: service.price_item,
                  quantity: service.quantiy,
                  description: '',
                  staff: service.staffs.map((member: any) => member.id),
                  type: 'service',
                })),
                ...response.data.bookings.offers.map((offer) => ({
                  id: offer.offer_id,
                  name: offer.name,
                  price: offer.price,
                  amount: offer.price,
                  quantity: offer.quantiy,
                  type: 'offer',
                  services: offer.services.map((service: any) => ({
                    id: service.id,
                    name: service.name,
                    price: service.price,
                    quantity: service.quantiy,
                    description: '',
                    staff: service.staffs.map((member: any) => member.id),
                    type: 'service',
                  })),
                })),
              ],
            } as Booking)
        )
      );
  }

  public findStaff = (): Observable<Staff> => {
    if (this.cache.staff) {
      return of(this.cache.staff);
    }

    return this._http
      .get<ApiResponse<Staff>>(environment.baseUrl + '/staffs')
      .pipe(
        map((response) => response.data),
        tap((staff) => (this.cache.staff = staff)),
        shareReplay(1)
      );
  };

  findCategories(): Observable<Category[]> {
    if (this.cache.categories) {
      return of(this.cache.categories);
    }

    return this._http
      .get<ApiResponse<Category[]>>(environment.baseUrl + '/categories')
      .pipe(
        map((response) => response.data),
        tap((categories) => {
          this.cache.categories = categories;
        })
      );
  }

  findServices(): Observable<Service[]> {
    if (this.cache.services) {
      return of(this.cache.services);
    }

    return this._http
      .get<ApiResponse<Service[]>>(environment.baseUrl + '/services')
      .pipe(
        map((response) => response.data),
        tap((services) => {
          this.cache.services = services;
        })
      );
  }

  findOffers(): Observable<Offer[]> {
    if (this.cache.offers) {
      return of(this.cache.offers);
    }

    return this._http
      .get<ApiResponse<Offer[]>>(environment.baseUrl + '/offers')
      .pipe(
        map((response) => response.data),
        tap((offers) => {
          this.cache.offers = offers;
        })
      );
  }

  public updateBooking = (booking: Booking): Observable<unknown> => {
    return this._http.put<unknown>(
      environment.bookingUrl + '/bookings/' + booking.id,
      this._preparePatchRequest(booking)
    );
  };

  private _preparePatchRequest(booking: Booking): any {
    const mappedServices: any[] = [];
    const mappedOffers: any[] = [];

    booking.products.forEach((s) => {
      if (s.type === 'service') {
        mappedServices.push({
          service_id: s?.id,
          staff_id: s?.staff,
          quantity: s?.quantity || 1,
          amount: s?.amount,
          date: '',
          time: '',
        });
      } else {
        mappedOffers.push({
          id: s.id,
          services: s.services.map((e) => ({
            service_id: e?.id,
            staff_id: e?.staff,
            quantity: s?.quantity || 1,
            amount: e?.amount,
            date: '',
            time: '',
          })),
        });
      }
    });

    return {
      user_id: booking.customer_id || null,
      reminder: Number(booking.is_reminder) ? 1 : 0,
      source: '4',
      time: DateTime.fromISO(booking.date).toFormat('HH:mm'),
      date: DateTime.fromISO(booking.date).toFormat('yyyy-MM-dd'),
      notes: booking.notes,
      specified_id: booking?.specified_id,
      status: booking.status_code,
      payment_method: booking.payment_method || '1',
      payment_amount: Number(booking.paid_amount),
      fees: +booking?.fees,
      services: mappedServices,
      offers: mappedOffers,
      used_balance: 0,
      cash_atm: {
        cash: booking.paid_cash,
        atm: booking.paid_atm,
      },
      discount: booking.discount_amount_type,
      discount_type: +booking.discount_type,
    };
  }

  public navigate = (route: 'customer' | 'services' | 'time'): void => {
    this._router.navigate([
      'new-reservation',
      'edit',
      this.booking.getValue().id,
      route,
    ]);

    this.navigationChange.next(route);
  };
}
