export const environment = {
  production: true,
  env: 'dev',
  baseUrl: 'https://system.pink-team.com/api-cachier',
  baseUrlWithoutApi: 'https://system.pink-team.com/',
  bookingUrl: 'https://booking.pink-team.com/api',
  bookingUrlWithoutApi: 'https://booking.pink-team.com/',
  // api_url: 'https://provider-api.pink-team.com/api-cashier',
  api_base_url: 'https://system.pink-team.com',
  upload_url: 'https://system.pink-team.com/uploads/',

  base_url: 'https://cashier.pink-team.com',
  pre_title: 'Pink Cahier | ',
  appVersion: require('../../package.json').version + '-Staging',

  googleAnalyticsId: 'G-HDVXYQJZJZ',
  notificationTimeInterval: 30000, // 30sec
};
