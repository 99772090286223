import * as moment from 'moment-timezone';
import { environment } from '../../environments/environment';
import { camelCase, kebabCase, snakeCase, startCase } from 'lodash-es';

export abstract class GStatic {
  public static myProp = 'Hello';

  public static tax: number = 15;

  public static doSomething(): string {
    return 'World';
  }

  public static log(...args): void {
    if (!environment.production && false) {
      args[0] = '%c' + args[0];
      args.splice(1, 0, 'background: #222; color: #bada55');
      console.log(...args);
    }
  }

  public static logImp(...args): void {
    if (!environment.production) {
      args[0] = '%c' + args[0];
      args.splice(1, 0, 'background: #991010; color: #ddd');
      console.log(...args);
    }
  }

  public static strToCamelCase(str): string {
    return camelCase(str);
  }

  public static strToKebabCase(str): string {
    return kebabCase(str);
  }

  public static strToSnakeCase(str): string {
    return snakeCase(str);
  }

  public static strToNormalCase(str): string {
    return startCase(str);
  }

  public static animate(
    from: number = 0,
    to: number = 300,
    fun: (x: number) => void,
    duration: number = 500,
    timerInterval1?: any
  ): ReturnType<typeof setTimeout> {
    clearInterval(timerInterval1);
    const easeInOutQuart = (t, b, c, d) => {
      // tslint:disable-next-line:no-conditional-assignment
      if ((t /= d / 2) < 1) {
        return (c / 2) * t * t * t * t + b;
      }
      return (-c / 2) * ((t -= 2) * t * t * t - 2) + b;
    };

    const start = new Date().getTime();
    const timerInterval = setInterval(() => {
      const time = new Date().getTime() - start;
      const x = easeInOutQuart(time, from, to - from, duration);
      fun(x);
      if (time >= duration) {
        clearInterval(timerInterval);
        fun(to);
      }
    }, 1000 / 60);

    return timerInterval;
  }

  public static to2digits(num: number | string) {
    return (num.toString().length === 1 ? '0' : '') + num.toString();
  }

  public static getTotalAfterTax(subtotal: number): number {
    return subtotal * ((100 + this.tax) / 100);
  }

  public static getTotalBeforeTax(total: number): number {
    return total * (100 / (100 + this.tax));
  }

  public static getTaxFromTotal(total: number): number {
    return total * (this.tax / (100 + this.tax));
  }

  public static removeDuplicate = <T>(list: T[], param: string = 'id'): T[] => {
    const uniqueObjects: T[] = [];

    const uniqueIds = new Set();

    list.forEach((obj) => {
      if (!uniqueIds.has(obj[param])) {
        uniqueIds.add(obj[param]);
        uniqueObjects.push(obj);
      }
    });

    return uniqueObjects;
  };

  public static getDayString = (
    year: number,
    month: number,
    day: number
  ): string => {
    const date = new Date(year, month - 1, day);

    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    return daysOfWeek[date.getDay()];
  };

  public static dateTimeZone = (
    date: string,
    format?: string
  ): Date | null | string => {
    const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the time zone ID

    const utcDate = moment.utc(date);

    return date ? utcDate.tz(timeZone).format(format) : null;
  };
}
