import { Pipe, PipeTransform } from '@angular/core';
import { Name } from 'app/models/api/reservation.interface';

@Pipe({
  name: 'staffName',
  standalone: true,
})
export class StaffNamePipe implements PipeTransform {
  public transform(name: string): Name {
    if (name.length) {
      const containsSplit: boolean = name?.includes('|');

      const names: string[] | string = containsSplit ? name?.split('|') : name;

      const arabicName: string = containsSplit
        ? (names as string[])
            ?.find((name: string) => /[\u0600-\u06FF]/.test(name))
            ?.trim()
        : name;

      const englishName: string = containsSplit
        ? (names as string[])
            ?.find((name: string) => !/[\u0600-\u06FF]/.test(name))
            ?.trim()
        : name;

      return { en: englishName, ar: arabicName };
    } else {
      return { en: name, ar: name };
    }
  }
}
