import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable, of, ReplaySubject, tap} from 'rxjs';
import {User} from 'app/core/user/user.types';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        const userFromStorage = JSON.parse(localStorage.getItem('user'));
        if (userFromStorage) {
            this._user.next(userFromStorage)
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        if (value) {
            localStorage.setItem('user', JSON.stringify(value))
        } else {
            localStorage.removeItem('user');
        }
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {
        if (localStorage.getItem('user')) {
            this._user.next(JSON.parse(localStorage.getItem('user')));
            return this._user.asObservable();
        } else {
            return this._httpClient.get<User>('api/common/user').pipe(
                tap((user) => {
                    this._user.next(user);
                })
            );
        }
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', {user}).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }
}
