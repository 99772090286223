import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "toTwoDecimals"
})
export class ToTwoDecimalsPipe implements PipeTransform {
  transform(value: number | string): string {
    if (!value) return Number(0).toFixed(2);

    if (typeof value === "string") {
      value = +value;
    }

    if (isNaN(value)) {
      return "";
    }

    return value.toFixed(2);
  }
}
