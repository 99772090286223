<style>
  .buttons-wrapper {
    display: flex;
    gap: 0.6rem;
  }
</style>

<div class="buttons-wrapper">
  <button
    *ngFor="let option of options; index as i"
    [disabled]="disabled"
    [ngClass]="{'bg-primary text-on-primary border-primary': isSelected(option[key])}"
    (click)="toggle(option[key])"
    type="button"
    mat-stroked-button
    class="rounded"
  >
    <div>{{option[value]}}</div>
  </button>
</div>

<div *ngIf="debug">
  {{selected | json}}
</div>