import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'timeZone' })
export class TimeZonePipe implements PipeTransform {
  public transform(date: string, format?: string): unknown {
    const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the time zone ID

    const utcDate = moment.utc(date);

    return date ? utcDate.tz(timeZone).format(format) : null;
  }
}
