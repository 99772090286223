import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * @created 5/4/2023
 */
export interface Option {
  [K: string]: string;
}

@Component({
  selector: 'multi-select-buttons',
  templateUrl: './multi-select-buttons.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiSelectButtonsComponent,
      multi: true
    }
  ]
})
export class MultiSelectButtonsComponent implements ControlValueAccessor, OnChanges {
  @Input() debug: boolean = false;
  @Input() disabled: boolean = false;
  @Input() key: string = 'key';
  @Input() value: string = 'value';
  @Input() options: Option[] = [];
  @Input() selected: string[] = [];

  @Output()
  readonly change: EventEmitter<string[]> = new EventEmitter<string[]>();

  private onChange: (value: string[]) => void = () => {};
  private onTouched: () => void = () => {};

  isSelected(value: string): boolean {
    return this.selected.includes(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  toggle(value: string): void {
    if (this.selected.includes(value)) {
      this.selected = this.selected.filter(
        v => v !== value
      );
    } else {
      this.selected = [...this.selected, value];
    }

    this.onChange(this.selected);
    this.change.emit(this.selected);
  }

  writeValue(value: string[]): void {
    this.selected = value || [];
  }
}