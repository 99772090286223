import { Pipe, PipeTransform, Signal } from '@angular/core';
import { DateTime } from 'luxon';
import { LanguageService } from '../services';

@Pipe({ name: 'myDate', pure: false })
export class MyDatePipe implements PipeTransform {
  public constructor(private readonly _languageService: LanguageService) {}

  public transform(
    value: string,
    toFormat: string = 'yyyy-MM-dd',
    fromFormat: string = null,
    ...args: unknown[]
  ): unknown {
    const local: Signal<string> = this._languageService.lang;

    let date: DateTime;
    if (fromFormat) {
      date = DateTime.fromFormat(value, fromFormat).setLocale(local());
    } else date = DateTime.fromISO(value).setLocale(local());
    if (!date.isValid) {
      if (value)
        date = DateTime.fromFormat(value, 'yyyy-MM-dd HH:mm:ss').setLocale(
          local()
        );
    }
    if (date.isValid) return date.toFormat(toFormat);
    else return value;
  }
}
