<div
  class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
>
  <!-- Navigation toggle button -->
  <div class="flex gap-2 items-center">
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>

    <ng-container *ngIf="!header.btns || header.btns.length === 0">
      <mat-icon
        *ngIf="header.icon"
        color="primary"
        [svgIcon]="header.icon"
      ></mat-icon>
      <h1 class="h-full text-xl font-bold text-primary">
        {{ pageTitle || header.title | transloco }}
      </h1>
    </ng-container>

    <ng-container *ngIf="header.btns?.length">
      <button
        *ngFor="let btn of header.btns"
        mat-stroked-button
        [routerLink]="[btn.routerLink]"
        class="btn.class"
        [routerLinkActive]="
          'bg-accent-200 text-on-accent-100 ' + btn.routerLinkActiveCss
        "
      >
        {{ btn.title }}
      </button>
    </ng-container>
  </div>
  <!-- Components -->
  <div class="flex items-center pl-2 ms-auto space-x-0.5 sm:space-x-2">
    <!-- Button -->
    <!--      <button
            mat-icon-button
            [matTooltip]="'Toggle Fullscreen'"
            class="px-1 " [class.bg-gray-50]="toggleCalender"
            (click)="toggleCalender = !toggleCalender"
        >
            <mat-icon [svgIcon]="'heroicons_outline:calendar'" [class.text-primary]="toggleCalender"></mat-icon>
        </button>-->

    <div class="flex justify-center items-center gap-2">
      <div #filters>
        <ng-content select="[filters]"></ng-content>
      </div>

      <div
        class="ml-1 w-1 min-h-5 h-full border-l"
        *ngIf="filters.children?.length"
      ></div>

      <div #ref>
        <ng-content select="[actions]"></ng-content>
      </div>

      <div
        class="ml-1 w-1 min-h-5 h-full border-l"
        *ngIf="ref.children?.length"
      ></div>
    </div>
    <fuse-fullscreen />

    <!--    <search [appearance]="'bar'"></search>
                <shortcuts></shortcuts>
               <messages></messages>
               <notifications></notifications>
               <button
                   class="lg:hidden"
                   mat-icon-button
                   (click)="quickChat.toggle()">
                   <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
               </button>
               <user></user>-->
  </div>
</div>
