import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ReservationDetails } from 'app/models/api/reservation.interface';
import { BaseDialog } from 'app/shared/base/BaseDialog';
import { NameVal } from 'app/shared/components/selective-btns/selective-btns.component';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

export interface CancelReservationData {
  reason: string;
  action: 'cancel' | 'submit';
}

@Component({
  selector: 'cancel-reservation-dialog',
  templateUrl: './cancel-reservation.dialog.html',
})
export class CancelReservationDialog extends BaseDialog implements OnInit {
  public constructor(
    private readonly _fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CancelReservationDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: ReservationDetails & { title: string },
    protected $gaService: GoogleAnalyticsService
  ) {
    super();
  }

  public get hasPartiallyPaid(): boolean {
    return Number(this.data.paid_amount) > 0;
  }

  public cancelForm: UntypedFormGroup = this._fb.group({
    reason: [null, Validators.required],
    refundMethod: [null, this.hasPartiallyPaid ? Validators.required : null],
  });

  public get PayList(): NameVal[] {
    return this.profileService.lookupsArr?.payment.filter(
      (payMethod: NameVal) =>
        payMethod?.value == '1' ||
        payMethod?.value == '2' ||
        payMethod?.value == '4' ||
        payMethod?.value == '7'
    );
  }

  public ngOnInit(): void {
    this.$gaService.pageView(
      '/dialog/cancel-reservation',
      'cancel-reservation'
    );
  }

  public close(): void {
    this.dialogRef.close({
      reason: '',
      action: 'cancel',
    });
  }

  public doCancelReservation(): void {
    if (this.cancelForm.invalid) return;

    this.dialogRef.close({
      ...this.cancelForm.value,
      action: 'submit',
    });
  }
}
