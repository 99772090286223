import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyDatePipe } from './pipes/my-date.pipe';
import { SelectiveBtnsComponent } from './components/selective-btns/selective-btns.component';
import { MatButtonModule } from '@angular/material/button';
import { MyCardComponent } from './components/my-card/my-card.component';
import { BtnComponent } from './components/btn/btn.component';
import { HeaderComponent } from './components/header/header.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseFullscreenModule } from '../../@fuse/components/fullscreen';
import { UserModule } from '../layout/common/user/user.module';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MultiSelectButtonsComponent } from './components/multi-select-buttons/multi-select-buttons.component';
import { ToNumPipe } from './pipes/to-num.pipe';
import { ToTwoDecimalsPipe } from './pipes/two-decimals.pipe';
import { SafeIntPipe } from './pipes/safe-int.pipe';
import { TwoDigitDecimalDirective } from './directives/two-digit-decimal.directive';
import { InvoiceViewComponent } from './components/invoice-view';
import { TranslocoModule } from '@ngneat/transloco';
import { StaffListComponent } from './components/staff-list/stuff-list.component';
import { SpreadStringsPipe } from './pipes/spread-strings.pipe';
import { TotalAmountBeforeTaxPipe } from './pipes/total-amount-before-tax.pipe';
import { TimeEqualPipe } from './pipes/time-equal.pipe';
import { TimeZonePipe } from './pipes/time-zone.pipe';
import { ShortStaffNamePipe } from 'app/modules/today-board/utils/pipes/short-staff-name.pipe';
import { StaffNamePipe } from './pipes/staff-name.pipe';

const COMPONENT: Array<any> | any = [
  SelectiveBtnsComponent,
  MyCardComponent,
  BtnComponent,
  HeaderComponent,
  MultiSelectButtonsComponent,
  TwoDigitDecimalDirective,
  InvoiceViewComponent,
  StaffListComponent,
];

const PIPES: Array<any> | any = [
  MyDatePipe,
  ToNumPipe,
  ToTwoDecimalsPipe,
  SafeIntPipe,
  SpreadStringsPipe,
  TotalAmountBeforeTaxPipe,
  TimeEqualPipe,
  TimeZonePipe,
  ShortStaffNamePipe,
];

@NgModule({
  declarations: [COMPONENT, PIPES],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    FuseFullscreenModule,
    UserModule,
    RouterLink,
    RouterLinkActive,
    TranslocoModule,
    StaffNamePipe,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatIconModule,
    TranslocoModule,
    COMPONENT,
    PIPES,
    StaffNamePipe,
  ],
  providers: [SafeIntPipe],
})
export class SharedModule {}
