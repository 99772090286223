import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, filter } from 'rxjs';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {  LanguageService } from './shared/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /**
   * Constructor
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _tr: TranslocoService,
    private titleService: Title,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,

    private readonly _languageService: LanguageService
  ) {

    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const df = this.prepareTitle();
        // Reset the element's scroll position to the top

        if (df)
          this.titleService.setTitle(
            environment.pre_title + this.prepareTitle()
          );
      });
  }


  prepareTitle() {
    let title: string = '';
    if (this._activatedRoute.snapshot?.data.title)
      title = this._activatedRoute.snapshot?.data.title;
    if (this._activatedRoute.snapshot.firstChild?.data.title)
      title = this._activatedRoute.snapshot.firstChild?.data.title;
    if (this._activatedRoute.snapshot.firstChild?.firstChild?.data.title)
      title = this._activatedRoute.snapshot.firstChild?.firstChild.data.title;
    return title;
  }
}
