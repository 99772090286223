import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from '../../services/profile.service';
import { AuthService } from '../../core/auth/auth.service';
import { Customer, CustomerReservation } from '../../models/api/customer';
import {
  ProviderOffer,
  ProviderService,
} from '../../models/api/providerService';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '../../models/api/ApiResponse.Interface';
import { DateTime } from 'luxon';
import { CustomerService } from '../../services/customer.service';
import { isEqual } from 'lodash-es';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  PaymentRequest,
  ReservationDetails,
  Staff,
} from '../../models/api/reservation.interface';
import { NavigationEnd, Router } from '@angular/router';
import { GStatic } from '../../services/g-static';
import { ToTwoDecimalsPipe } from 'app/shared/pipes/two-decimals.pipe';
import * as moment from 'moment-timezone';

export interface SelectedServiceItem {
  type: 'offer' | 'service';
  minutes: number;
  amount: number;
  amount_before?: number;
  discount?: number;
  count: number;
  staffId: Staff;
  name: string;
  id: number;
  obj: any;
}

export interface OfferService {
  id: number;
  staffs: any[];
}

export interface Offer {
  offer_id: number;
  services: any[];
}

export interface SubmitBody {
  user_id: number;
  date: string;
  time: string;
  voucher: string;
  offer_id: string;
  notes: string;
  status: string;
  payment_method: string;
  discount: string;
  is_reminder: 0 | 1;
  is_confirmation: 0 | 1;
  partial_payment: string;
  used_balance: string;
  paid_amount: string;
  cash_atm: { cash: string; atm: string };
  discount_type: string;
  service: any[];
  offers: any[];
}

@Injectable({ providedIn: 'root' })
export class ReservationService {
  private bookingForm: UntypedFormGroup;
  public prevReservation: ReservationDetails;

  private prevPage: string;

  constructor(
    private _profile: ProfileService,
    private _httpClient: HttpClient,
    private _auth: AuthService,
    private _customer: CustomerService,
    private _formBuilder: UntypedFormBuilder,
    private router: Router,
    private _toTwoDecimals: ToTwoDecimalsPipe
  ) {
    this.router.events.subscribe((event) => {
      /*   if (event instanceof NavigationEnd) {
                  GStatic.log('ReservationService NavigationEnd ...', event)
               }*/
      if (event instanceof NavigationEnd) {
        if (
          !event.urlAfterRedirects.startsWith('/new-reservation') &&
          this.prevPage?.startsWith('/new-reservation')
        ) {
          this.reset(true);
        }
        this.prevPage = event.url;
      }
    });
  }

  customerPageMode: 'create' | 'view' | 'search' = 'view';

  reservationType: 'salon' | 'home' | 'continue' | 'oldReservation' = null;

  private _customerSearchMobile: string = '';

  public set customerSearchMobile(val: string) {
    this._customerSearchMobile = val;
  }

  public get customerSearchMobile(): string {
    return this._customerSearchMobile;
  }

  doneReservation: any;

  submitBody: SubmitBody = {} as SubmitBody;

  private selectedCustomerSubject = new BehaviorSubject<Customer>(null);
  selectedCustomer: Customer;

  private _selectedTime: string;

  private selectedServicesSubject = new BehaviorSubject<SelectedServiceItem[]>(
    []
  );
  selectedServices$ = this.selectedServicesSubject.asObservable();

  private selectedOffers$$ = new BehaviorSubject<Offer[]>([]);
  selectedOffers$ = this.selectedOffers$$.asObservable();

  get selectedOffers() {
    return this.selectedOffers$$.getValue();
  }

  setSelectedOffers(offers: Offer[]) {
    this.selectedOffers$$.next(offers);
  }

  get selectedServices() {
    return this.selectedServicesSubject.getValue();
  }

  servicesMeta = {
    totalWithoutDiscounts: 0,
    tax: 0,
    servicesCount: 0,
    offersDiscount: 0,
  };

  get selectedCustomer$() {
    return this.selectedCustomerSubject.asObservable();
  }

  get selectedTime() {
    return this._selectedTime;
  }

  get invalid() {
    let invalid = false;
    if (this.servicesMeta?.servicesCount === 0) invalid = true;
    if (!this.selectedCustomer || !this.selectedCustomer.id) invalid = true;
    if (!this.selectedTime) invalid = true;
    return invalid;
  }

  async setReservationDetails(reservation: ReservationDetails) {
    const customer = await lastValueFrom(
      this._customer.findCustomer(reservation.customer_mobile)
    );
    this.setCustomer(customer);
    this.selectedTime = reservation.date;
    this.prevReservation = reservation;
    this.formBuilder();
  }

  isHomeService(): boolean {
    return this.reservationType === 'home';
  }

  startReservation(
    type: 'salon' | 'home' | 'continue' | 'oldReservation',
    reservation: CustomerReservation = null
  ) {
    if (this.reservationType && type != 'continue') {
      this.reset();
    }
    this.reservationType = type;

    switch (type) {
      case 'salon':
        GStatic.log('startReservation', type);
        break;
      case 'home':
        GStatic.log('startReservation', type);
        break;
      case 'continue':
        GStatic.log('startReservation', type);
        break;
      case 'oldReservation':
        // this.reset();
        GStatic.log('startReservation', type);
        break;
    }
  }

  setCustomer(customer: Customer) {
    this.selectedCustomer = customer;
    this.customerSearchMobile = customer?.mobile;
    this.selectedCustomerSubject.next(customer);
  }

  // ****************************
  // second Page 2.Set Date Time
  // ****************************

  set selectedTime(val) {
    this._selectedTime = val;
    this.submitBody.date = DateTime.fromISO(this.selectedTime).toFormat(
      'yyyy-MM-dd'
    );
    this.submitBody.time = DateTime.fromISO(this.selectedTime).toFormat(
      'hh:mm a'
    );
  }

  // ***********************
  // second Page 3.services
  // ***********************

  addSelectedService(service: ProviderService, staff: Staff = []) {
    const services = this.selectedServicesSubject.getValue();

    this.selectedServicesSubject.next([
      ...services,
      {
        type: 'service',
        id: Number(service.id),
        count: 1,
        amount: Number(service.amount),
        name: service.name,
        minutes: Number(service.services_time),
        obj: service,
        staffId: staff || [],
      },
    ]);
    this.updateMeta();
  }

  addSelectedOffer(offer: ProviderOffer, staff: Staff = []) {
    const purchased = this.selectedServicesSubject.getValue();
    let result: SelectedServiceItem[] = [];
    let searchResult = purchased.find(
      (s) => s.id?.toString() === offer.id?.toString()
    );

    if (searchResult) {
      if (searchResult.count < 1) {
        searchResult.count++;
        searchResult.amount += Number(offer.amount) || 0;
        searchResult.minutes += Number(offer.estimated_time) || 0;
      }
      result = purchased;
    } else {
      searchResult = {
        type: 'offer',
        id: Number(offer.id),
        count: 1,
        amount: Number(offer.amountBefore) || 0,
        discount: Number(offer.amountBefore) - Number(offer.amount),
        name: offer.name,
        minutes: Number(offer.estimated_time) || 0,
        obj: offer,
        staffId: staff || [],
      };
      result = [...purchased, searchResult];
    }

    searchResult.obj.services.forEach(
      (e) => (e.selectedStaff = staff.map((s) => +s) || [])
    );

    this.selectedServicesSubject.next(result);
    this.updateMeta();
  }

  updateSelectedService(selectiveService: SelectedServiceItem) {
    if (selectiveService.count === 0) {
      this.deleteSelectedService(selectiveService.id);
    } else {
      if (selectiveService) {
        if (selectiveService.type === 'service') {
          selectiveService.amount =
            selectiveService.count * Number(selectiveService.obj?.amount || 0);
          selectiveService.minutes =
            selectiveService.count *
            Number(selectiveService.obj?.services_time);
        } else {
          /*   selectiveService.amount = selectiveService.count * Number(selectiveService.obj?.amount || 0);
                       selectiveService.minutes = selectiveService.count * Number(selectiveService.obj?.services_time);*/
        }
      } else {
        throw 'no selected service with this id';
      }
      this.updateMeta();
    }
  }

  updateServiceStartTime(
    serviceId: string,
    time: string,
    duration: number,
    staff: any = [],
    isOffer?: boolean
  ) {
    const body = {
      staff_id: staff,
    };
    if (time) body['from'] = time;

    if (duration) body['duration'] = duration;

    if (!staff.length) body['staff_id'] = [null];

    body['offer'] = isOffer;

    return this._httpClient.post<ApiResponse<any>>(
      environment.bookingUrl + `/edit-time/${serviceId}`,
      body
    );
  }

  deleteSelectedService(serviceId: number) {
    const services = this.selectedServicesSubject.getValue();
    let searchResult = services.filter((s) => s.id !== serviceId);

    this.selectedServicesSubject.next(searchResult);
    this.updateMeta();
  }

  public deleteServiceByIndex = (
    service: SelectedServiceItem,
    serviceIndex: number
  ): void => {
    if (!!service) {
      this.selectedServicesSubject.next(
        this.selectedServicesSubject
          .getValue()
          .filter((sService: SelectedServiceItem, index: number) =>
            sService?.id === service?.id
              ? index !== serviceIndex
              : sService?.id !== service?.id
          )
      );

      this.updateMeta();
    }
  };

  // *********************************
  // second Page 4.Summary & payment
  // *********************************

  /**
   * Products is a union term for offers and services.
   */
  private updateMeta() {
    const products = this.selectedServicesSubject.getValue();

    let totalAmountAfterTax = 0;
    let offersDiscount = 0;

    products.forEach((product: any) => {
      if (this._isOffer(product)) {
        if (this._salonHasVat()) {
          offersDiscount += GStatic.getTotalBeforeTax(product.discount) || 0;
        } else {
          offersDiscount += product.discount || 0;
        }
      }

      if (this._isService(product)) {
      }

      if (this._salonHasVat()) {
        totalAmountAfterTax += GStatic.getTotalBeforeTax(product.amount);
      } else {
        totalAmountAfterTax += product.amount;
      }
    });

    this.servicesMeta.servicesCount = products.length;
    this.servicesMeta.totalWithoutDiscounts = totalAmountAfterTax;
    this.servicesMeta.offersDiscount = offersDiscount;

    this.servicesMeta.tax =
      Math.round(((totalAmountAfterTax * 15) / 115) * 100) / 100;

    /*
            how I get the tax from afterTaxTotal:

            totalAfterTax = totalAmountBeforeTax + totalAmountBeforeTax * 15 / 100;
            totalAfterTax = totalAmountBeforeTax * (100/100 + 15/100);
            totalAfterTax = totalAmountBeforeTax * 115/100;
            totalAmountBeforeTax = totalAfterTax * 100 / 115

            tax = totalAmountBeforeTax * 15/100
            tax = (totalAfterTax * 100 / 115) * 15/100
            tax = totalAfterTax * 15/115 // = 0.1304347


tax = totalAfterTax * 15/115 // = 0.1304347


         */

    /*  const finalTotal =
          this.servicesMeta.total
            + this.servicesMeta.tax
            + this.servicesMeta.deliveryAmount
            - this.servicesMeta.discount
            - this.servicesMeta.balance
        this.servicesMeta.finalTotal = Math.round(finalTotal * 100) / 100;
        this.servicesMeta.toPaid = Math.round((finalTotal - this.servicesMeta.remain) * 100) / 100;*/

    GStatic.log('servicesMeta', this.servicesMeta);
  }

  // *********************************
  // 5.submit And Reset
  // *********************************

  submitReservation(value: any) {
    let mappedServices: any[] = [];
    let mappedOffers: any[] = [];

    this.selectedServicesSubject.getValue().forEach((s) => {
      if (s.type === 'service') {
        mappedServices.push({
          service_id: s?.id,
          staff_id: s?.staffId,
          quantity: 1,
          amount: s?.amount,
          date: '',
          time: '',
        });
      } else {
        mappedOffers.push({
          id: s.id,
          services: s.obj.services.map((e) => ({
            service_id: e?.id,
            staff_id: e?.selectedStaff,
            quantity: 1,
            amount: e?.amount,
            date: '',
            time: '',
          })),
        });
      }
    });

    const payload = {
      source: '4',
      reminder: Number(value.isReminder) ? 1 : 0,
      time: DateTime.fromISO(this.selectedTime).toFormat('HH:mm'),
      date: DateTime.fromISO(this.selectedTime).toFormat('yyyy-MM-dd'),
      user_id: this.selectedCustomer.id,
      notes: value.note,
      specified_id:
        this._profile.previousReservationType.value === 'salon' ? 1 : 2,
      status: value.arrivalStatus,
      payment_method: value.partialPaymentStatus == 2 ? 0 : value.paymentMethod,
      payment_amount: Number(value.paidAmount),
      fees: +this._profile.lookup()?.salon,
      services: mappedServices,
      offers: mappedOffers,
      used_balance: Number(value.usedBalanceAmount),
      cash_atm: { cash: value.cashAtm_cash || 0, atm: value.cashAtm_atm || 0 },
      discount: value.discountAmount,
      discount_type: value.discountType,
      is_confirmation: value.isConfirmation,
    };

    if (value.address) payload['address'] = value.address;

    return this._httpClient
      .post<ApiResponse<any>>(environment.bookingUrl + '/bookings', payload)
      .pipe(
        tap((response) => {
          this.doneReservation = response.data;
        })
      );
  }

  isStarted() {
    const servicesMeta = isEqual(this.servicesMeta, {
      totalWithoutDiscounts: 0,
      tax: 0,
      servicesCount: 0,
      offersDiscount: 0,
    });
    return !(
      !this.reservationType &&
      !this.selectedCustomer &&
      !this._selectedTime &&
      servicesMeta
    );
  }

  reset(hard: boolean = false) {
    // reservationType:  'salon' | 'home' | 'continue' | 'oldReservation' = null;
    this.bookingForm = undefined;
    this.customerSearchMobile = '';
    this.doneReservation = undefined;
    this.prevReservation = undefined;
    this.submitBody = {} as SubmitBody;
    this.selectedCustomerSubject.next(null);

    this.selectedCustomer = undefined;

    this._selectedTime = undefined;

    this.selectedServicesSubject.next([]);
    this.servicesMeta = {
      totalWithoutDiscounts: 0,
      tax: 0,
      servicesCount: 0,
      offersDiscount: 0,
    };

    if (hard) {
      this.reservationType = null;
      this._customer.reset();
    }
  }

  formBuilder() {
    if (this.bookingForm) {
      return this.bookingForm;
    }
    let defaultValues = {
      address: '',
      customerId: this.selectedCustomer?.id || null,
      reservationTime: this.selectedTime || null,
      serviceCount: this.servicesMeta.servicesCount,
      arrivalStatus: '5',
      isConfirmation: false,
      isReminder: false,
      partialPaymentStatus: '0',
      paymentMethod: '1',
      note: '',
      paidAmount: 0,
      cashAtm_atm: null,
      cashAtm_cash: null,
      oldPaidAmount: 0,
      oldPaymentDetails: null,
      usedBalance: false,
      usedBalanceAmount: 0,
      offersDiscount: 0,
      discountType: 0,
      discountAmount: '0',
      calculatedDiscountAmount: 0,
      remainingAmount: 0,
      finalTotal: 0,
    };

    if (this.prevReservation) {
      if (DateTime.fromISO(this.prevReservation.date).isValid)
        this._selectedTime = this.prevReservation.date;
      else
        this._selectedTime = DateTime.fromFormat(
          this.prevReservation.date,
          'yyyy-MM-dd hh:mm:ss'
        ).toISO();

      const selectedServices =
        this.prevReservation.services.map<SelectedServiceItem>((service) => ({
          type: 'service',
          id: Number(service.id), // TODO: Hazem service.id or  service.booking_service_id ??
          count: Number(service.quantiy),
          staffId: service.staffs?.map((x) => ({
            id: +x.id,
            name: x.name,
            name_lang: { en: x.name.split('|')[0], ar: x.name.split('|')[1] },
          })),
          minutes: service.duration,
          amount: Number(service.price),
          name: service.name,
          obj: this._profile.services.find(
            (s) => s.id?.toString() === service.id?.toString()
          ),
        }));

      this.prevReservation.offers.forEach((offer) => {
        offer.services.forEach((service) => {
          if (offer.staffId?.length) {
            offer.staffId = [
              ...offer.staffId,
              ...service.staffs?.map((x) => ({
                id: +x.id,
                name: x.name,
                name_lang: {
                  en: x.name.split('|')[0],
                  ar: x.name.split('|')[1],
                },
              })),
            ];
          } else {
            offer.staffId = service.staffs?.map((x) => ({
              id: +x.id,
              name: x.name,
              name_lang: { en: x.name.split('|')[0], ar: x.name.split('|')[1] },
            }));
          }
        });
      });

      const selectedOffers =
        this.prevReservation.offers.map<SelectedServiceItem>((offer) => ({
          type: 'offer',
          id: Number(offer.offer_id),
          count: Number(offer.quantity || 1),
          staffId: offer.staffId,
          minutes: 0,
          amount: Number(offer.price),
          amount_before: Number(offer.price_before),
          name: offer.name,
          obj: this._profile.offers.find(
            (s) => s.id?.toString() === offer.offer_id?.toString()
          ),
        }));

      this.selectedServicesSubject.next([
        ...selectedOffers,
        ...selectedServices,
      ]);

      this.updateMeta();
      defaultValues = {
        address: this.prevReservation.address || null,
        customerId: this.selectedCustomer?.id || null,
        reservationTime: this.selectedTime || null,
        serviceCount: this.servicesMeta.servicesCount,
        offersDiscount: this.servicesMeta.offersDiscount,
        arrivalStatus: this.prevReservation.status_code,
        isConfirmation:
          this.prevReservation.is_confirmation?.toString() === '1',
        isReminder: this.prevReservation.is_reminder?.toString() === '1',
        partialPaymentStatus:
          this.prevReservation.total === this.prevReservation.paid_amount
            ? '2'
            : '0',
        paymentMethod: this.prevReservation.payment_method.toString() || '1',
        note: this.prevReservation.notes,
        paidAmount: Number(this.prevReservation.payment_method) || 0,
        cashAtm_atm: this.prevReservation.paid_atm || null,
        cashAtm_cash: this.prevReservation.paid_cash || null,
        oldPaidAmount: Number(this.prevReservation.paid_amount),
        oldPaymentDetails: {
          date: this.prevReservation.created_at,
          paymentMethod: this.prevReservation.payment_method,
          paymentMethodTxt:
            this._profile.lookups.payment_methods[
              this.prevReservation.payment_method
            ],
          // partialPaymentStatus: this.prevReservation.source,
          paidAmount: this.prevReservation.paid_amount,
        },
        usedBalance: false,
        usedBalanceAmount: 0,
        discountType: 0,
        discountAmount: this.prevReservation.discount.toString() || '0',
        calculatedDiscountAmount: 0,
        remainingAmount: 0,
        finalTotal: Number(this.prevReservation.total),
      };
    }

    this.bookingForm = this._formBuilder.group({
      address: [defaultValues.address],
      customerId: [defaultValues.customerId, [Validators.required]],
      reservationTime: [defaultValues.reservationTime, [Validators.required]],
      serviceCount: [defaultValues.serviceCount, [Validators.required]],

      arrivalStatus: [defaultValues.arrivalStatus, [Validators.required]],
      isConfirmation: [defaultValues.isConfirmation],
      isReminder: [defaultValues.isReminder],

      partialPaymentStatus: [
        defaultValues.partialPaymentStatus,
        [Validators.required],
      ],
      paymentMethod: [defaultValues.paymentMethod, [Validators.required]],
      note: [defaultValues.note],

      paidAmount: [defaultValues.paidAmount, [Validators.required]],
      cashAtm_atm: [defaultValues.cashAtm_atm],
      cashAtm_cash: [defaultValues.cashAtm_cash],

      offersDiscount: [defaultValues.offersDiscount],

      oldPaidAmount: [defaultValues.oldPaidAmount],
      oldPaymentDetails: [defaultValues.oldPaymentDetails],
      usedBalance: [defaultValues.usedBalance, [Validators.required]],
      usedBalanceAmount: [
        defaultValues.usedBalanceAmount,
        [Validators.required],
      ],
      discountType: [defaultValues.discountType],
      discountAmount: [defaultValues.discountAmount],
      calculatedDiscountAmount: [defaultValues.calculatedDiscountAmount],
      remainingAmount: [defaultValues.remainingAmount, [Validators.required]],
      finalTotal: [defaultValues.finalTotal, [Validators.required]],
    });
    return this.bookingForm;
  }

  updateReservation(value: any) {
    const offers = this.selectedOffers.map((offer) => ({
      id: offer.offer_id,
      services: offer.services.map((service: OfferService) => ({
        service_id: service.id,
        staff_id: service.staffs.map((staff) => staff.id),
      })),
    }));

    // prepare body data
    let mappedServices: any[] = [];

    this.selectedServicesSubject.getValue().forEach((s) => {
      if (s.type === 'service') {
        const asd = s.staffId || [];

        mappedServices.push({
          service_id: s.id,
          staff_id: Array.isArray(s.staffId)
            ? s.staffId.map((staff) => staff.id)
            : s.staffId,
          sessions: 1,
          quantity: s.count,
        });
      }
    });

    const body = {
      source: '1',
      time: moment(this.selectedTime, 'h:mm A').format('HH:mm'),
      address: value.address,
      date: DateTime.fromISO(this.selectedTime).toFormat('yyyy-MM-dd'),
      user_id: this.selectedCustomer.id,
      voucher: null,
      notes: value.note,
      status: value.arrivalStatus,
      payment_method: value.paymentMethod,
      discount: value.discountAmount,
      is_reminder: Number(value.isReminder) ? 1 : 0,
      is_confirmation: Number(value.isConfirmation) ? 1 : 0,

      partial_payment: value.partialPaymentStatus,
      used_balance: Number(value.balance),
      paid_amount: value.partialPaymentStatus == 2 ? 0 : value.paidAmount,
      cash_atm: { cash: value.cash, atm: value.atm },
      discount_type: value.discountType,

      service: mappedServices.length ? mappedServices : null,
      offers,
    };

    return this._httpClient
      .put<ApiResponse<any>>(
        environment.bookingUrl + '/bookings' + this.prevReservation.id,
        body
      )
      .pipe(
        tap((response) => {
          this.doneReservation = response.data;
        })
      );
  }

  sendSms() {
    return this._httpClient.post(
      environment.bookingUrl + '/send-sms/' + this.doneReservation.id,
      {}
    );
  }

  /**
   * Pay a reservation.
   *
   * @param {PaymentRequest} request
   * @returns {Observable<PaymentResponse>}
   */
  pay(request: PaymentRequest): Observable<ApiResponse<PaymentResponse>> {
    const id = request.reservationId;
    const requestBody = request;
    delete requestBody.reservationId;
    return this._httpClient.get<ApiResponse<PaymentResponse>>(
      environment.bookingUrl + '/payment/' + id,
      { params: requestBody as any }
    );
  }

  private _isHomeService(): boolean {
    return this.reservationType === 'home';
  }

  private _salonHasVat(): boolean {
    return this._profile.hasVat;
  }

  private _isOffer(product: any): boolean {
    return product.type === 'offer';
  }

  private _isService(product: any): boolean {
    return product.type === 'service';
  }
}
